// Navbar 
import Navbar from "../components/navbar/Navbar";

// Content 
import Headline from "../components/home/Headline";
import AboutUs from "../components/home/AboutUs";
import VisionMission from "../components/home/VisionMission";
import Partner from "../components/home/Partner";
import Call from "../components/home/Call";
import Location from "../components/home/Location";
import OurTeam from "../components/home/OurTeam";
import Service from "../components/home/Service";
import Achievement from "../components/home/Achievement";

// Footer 
import Footer from "../components/footer/Footer";

const Home = () => {
    return (
        <>
            <div className="navbar">
                <Navbar />
            </div>
            <div className="content">
                <Headline />
                <AboutUs />
                <Location />
                <Achievement />
                <div className="line"></div>
                <Partner />
                <Call />
                <Service />
                <div className="line"></div>
                <OurTeam />
                <div className="line"></div>
                <VisionMission />
            </div>
            <div className="footer">
                <Footer />
            </div>
        </>
    );
}

export default Home;