// Navbar
import BackNavbar from "../components/navbar/BackNavbar";

// Content
// import BlankBlog from "../components/blog/BlankBlog";
import Headline from "../components/artikel/Headline";
import Articles from "../components/artikel/Articles";

// Footer
import Footer2 from "../components/footer/Footer2";

const Artikel = () => {
    return (
        <>
            <div className="navbar">
                <BackNavbar />
            </div>
            <div className="content">
                <Headline />
                <Articles />
            </div>
            <div className="footer">
                <Footer2 />
            </div>
        </>
    );
}

export default Artikel;