import "./Blog.css";
import { useState } from "react";
import { blogsData } from "./data";
import { Link } from 'react-router-dom';

// import Terminal from "../../images/news/terminal2.png";

const FilledBlog = () => {
    const [blogs] = useState(blogsData);

    const [currentPage, setCurrentPage] = useState(1);
    const blogsPerPage = 9;

    const indexOfLastBlog = currentPage * blogsPerPage;
    const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
    const currentBlog = blogs.slice().reverse().slice(indexOfFirstBlog, indexOfLastBlog);

    const totalPages = Math.ceil(blogs.length / blogsPerPage);

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handlePrevPage = () => {
        setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) => (prevPage < totalPages ? prevPage + 1 : prevPage));
    };

    return (
        <>
            <section className="sec-blogs">
                <div className="blogs-container">
                    <div className="cards">
                        {
                            currentBlog.map((blog, i) => (
                                <li key={blog.id}>
                                    <Link to={`/blog/${blog.id}/${blog.title}`}>
                                        <div key={i} className="card" >
                                            <img src={blog.image} alt="" />
                                            <h3 id="title">{blog.title}</h3>
                                            <div className="blog-info">
                                                <p>{blog.sumber}</p>
                                                <p id="divider">/</p>
                                                <p>{blog.tahun}</p>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            ))
                        }
                    </div>
                    <div className="pagination">
                        <button onClick={handlePrevPage} disabled={currentPage === 1}>
                            Prev
                        </button>
                        {Array.from({ length: totalPages }).map((_, index) => (
                            <button
                                key={index}
                                onClick={() => paginate(index + 1)}
                                className={currentPage === index + 1 ? "selected" : "not-selected"}
                            >
                                {index + 1}
                            </button>
                        ))}
                        <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                            Next
                        </button>
                    </div>
                </div>
            </section>

        </>
    );
}

export default FilledBlog;