import "./Home.css";
import { useState } from "react";
import Profile from "../../images/icon/Profile.png";

const OurTeam = () => {
    const [cards] = useState([
        {
            id: 1,
            image: Profile,
            name: "Ardiansyah Yendra",
            position: "Direktur Utama"
        },
        {
            id: 2,
            image: Profile,
            name: "Koko Tias Ernando",
            position: "Direktur Operasional"
        },
        {
            id: 3,
            image: Profile,
            name: "Ayu Sri Pattaya F.",
            position: "Direktur Keuangan"
        },
    ]);
    return (
        <>
            <section>
                <div className="our-team-container">
                    <div className="cards">
                        <h1>Tim Kami</h1>
                        <div className="content">
                            {
                                cards.map((card, i) => (
                                    <div className="card" key={card.id}>

                                        <img src={card.image} alt="" />
                                        <div className="data-profile">
                                            <h4 id="name">{card.name}</h4>
                                            <p>{card.position}</p>
                                        </div>

                                    </div>
                                ))
                            }

                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}

export default OurTeam;