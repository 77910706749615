import "./Footer.css";
// Component under footer
import Comp1 from "../../images/component/footer-c.png";
import Comp2 from "../../images/component/footer-c4.png";
import Comp3 from "../../images/component/footer-c3.png";

// AMJ Logo
import Logo from "../../images/logo/def-amj.png";

// Social Media Logo
import Facebook from "../../images/icon/Facebook.png";
import Twitter from "../../images/icon/Twitter.png";
import Instagram from "../../images/icon/Instagram.png";
import LinkedIn from "../../images/icon/LinkedIn.png";
import YouTube from "../../images/icon/YouTube.png";

// Contact Icon
import Email from "../../images/icon/Email.png";
import Mark from "../../images/icon/Mark.png";
import Call from "../../images/icon/Call.png";

const Footer = () => {
    return (
        <>
            <img src={Comp1} alt="" id="c-footer-img" />
            <img src={Comp2} alt="" id="c2-footer-img" />
            <img src={Comp3} alt="" id="c3-footer-img" />
            <div className="footer-container" id="footer">
                <div className="content">
                    <div className="left">
                        <div className="c1">
                            <div className="title">
                                <img src={Logo} alt="" />
                                <h2>PT. Alfath Mulia Jaya</h2>
                            </div>
                            <p>Ikuti kami untuk informasi lebih lanjut tentang pembaruan sewa mobil dan layanan mobil.</p>
                            <div className="sosmed">
                                <img src={Facebook} alt="" id="fb" />
                                <img src={Twitter} alt="" />
                                <img src={Instagram} alt="" />
                                <img src={LinkedIn} alt="" />
                                <img src={YouTube} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <div className="c2">
                            <h4>Dukungan</h4>
                            <ul>
                                <li><a href="/#help-call">Bantuan</a></li>
                            </ul>
                        </div>
                        <div className="c3">
                            <h4>Perusahaan</h4>
                            <ul>
                                <li><a href="/#about-us">Tentang Kami</a></li>
                                <li><a href="/#footer">Kontak</a></li>
                                <li><a href="/blog">Blog</a></li>
                            </ul>
                        </div>
                        <div className="c4">
                            <h4>Kontak Kami</h4>
                            <div className="contact">
                                <div className="footer-email">
                                    <img src={Email} alt="" />
                                    <p>alfathmulyajaya@gmail.com</p>
                                </div>
                                <div className="footer-call">
                                    <img src={Call} alt="" />
                                    <p>031-8559-4652</p>
                                </div>
                                <div className="footer-mark">
                                    <img src={Mark} alt="" />
                                    <p>Jl. Letjen Sutoyo No.126 Medaeng, Kecamatan Waru, Kabupaten Sidoarjo</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-line"></div>
                <div className="copyright">
                    <p>© 2023 PT. Alfath Mulia Jaya</p>
                    <div className="adds">
                        <p>Semua hak dilindungi</p>
                        <p id="divider">|</p>
                        <a href="/">Syarat dan Ketentuan</a>
                        <p id="divider">|</p>
                        <a href="/">Kebijakan Privacy</a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer;