// Navbar
import BackNavbar from "../components/navbar/BackNavbar";

// Content
import ADescription from "../components/service_detail/ADescription";
import ALocation from "../components/service_detail/ALocation";
import ABenefit from "../components/service_detail/ABenefit";
import AOffer from "../components/service_detail/AOffer";

// Footer
import Footer from "../components/footer/Footer";

const ADetail = () => {
    return (
        <>
            <div className="navbar">
                <BackNavbar />
            </div>
            <div className="content">
                <ADescription />
                <ALocation />
                <ABenefit />
                <AOffer />
            </div>
            <div className="footer">
                <Footer />
            </div>
        </>
    );
}

export default ADetail;