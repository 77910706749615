import "./ServiceDetail.css";

const AOffer = () => {
    return (
        <>
            <div className="adds-offer-container">
                <h1>Apa anda tertarik untuk menggunakan layanan kami? </h1>
                <p>Anda dapat menghubungi kami secara langsung melewati email, telepon kantor atau berkunjung ke alamat kami. Untuk informasi lebih lanjut, anda dapat berkonsultasi langsung dengan menekan tombol di bawah berikut.</p>
                <a href="#footer"><button className="btn-fill-blue">Kontak Kami</button></a>
            </div>
        </>
    );
}

export default AOffer;