import "./ServiceDetail.css";
import { useState } from "react";
import Advertise from "../../images/place/square/advertise.png";
import Add1 from "../../images/place/album/advertise/image1.png";
import Add2 from "../../images/place/album/advertise/image2.png";
import Add3 from "../../images/place/album/advertise/image3.png";
import Add4 from "../../images/place/album/advertise/image4.png";
import Add5 from "../../images/place/album/advertise/image5.png";

const ALocation = () => {
    const [cards] = useState([
        {
            id: 1,
            image: Advertise,
            location: "Transportasi Online Bandara Juanda",
            detail: "Jl. Ir. H. Juanda, Betro, Kec. Sedati, Kabupaten Sidoarjo, Jawa Timur",
            map: "https://goo.gl/maps/TJ43faTTrJbJp6vG6",
            albums: [
                {
                    id: 1,
                    photo: Add1
                },
                {
                    id: 2,
                    photo: Add2
                },
                {
                    id: 3,
                    photo: Add3
                },
                {
                    id: 4,
                    photo: Add4
                },
                {
                    id: 5,
                    photo: Add5
                },
            ]
        },
    ]);
    return (
        <>
            <section>
                <div className="adds-location-container">
                    <h1 id="title">Lokasi</h1>
                    <div className="cards">
                        {
                            cards.map((card, i) => (
                                <>
                                    <div className="card">
                                        <img src={card.image} alt="" />
                                        <div className="detail">
                                            <div className="loc-data">
                                                <h1>
                                                    {card.location}
                                                </h1>
                                                <p>
                                                    {card.detail}
                                                </p>
                                            </div>
                                            <a href={card.map}><button className="btn-fill-blue">Lihat di Google Maps</button></a>
                                        </div>
                                    </div>
                                    <div className="albums">
                                        {
                                            card.albums.map((album, i) => (
                                                <img src={album.photo} alt="" />
                                            ))
                                        }
                                    </div>
                                </>
                            ))
                        }
                    </div>
                </div>
            </section>
        </>
    );
}

export default ALocation;