import "./Home.css";
import Car from "../../images/icon/Car.png";
import Horn from "../../images/icon/Horn.png";

const Service = () => {
    return (
        <>
            <div className="service-container" id="service">
                <h1>Layanan Kami</h1>
                <div className="cards">
                    <div className="card-horn">
                        <div className="content">
                            <img src={Horn} alt="" id="horn" />
                            <h2>Jasa Periklanan Videotron</h2>
                            <p>Promosikan produk anda dengan cara yang menarik</p>
                        </div>
                        <a href="/advertise"> <button className="btn-fill-blue">Detail &gt;</button></a>

                    </div>
                    <div className="card-car">
                        <div className="content">
                            <img src={Car} alt="" id="car" />
                            <h2>Angkutan Sewa Khusus</h2>
                            <p>Siap memberikan angkutan sewa khusus terbaik</p>
                        </div>
                        <a href="/special_rent"><button className="btn-fill-blue">Detail &gt;</button></a>

                    </div>

                </div>
            </div>
        </>
    );
}

export default Service;