import "./ServiceDetail.css";

const SRDescription = () => {
    return (
        <>
            <div className="detail-desc-container">
                <div className="content">
                    <h1>Angkutan Sewa Khusus</h1>
                    <p>Angkutan Sewa Khusus adalah pelayanan angkutan dari pintu ke pintu dengan pengemudi, memiliki wilayah operasi dan pemesanan menggunakan aplikasi berbasis teknologi informasi. </p>
                    <p>Layanan ini dapat anda gunakan bila tidak ingin mengendarai kendaraan pribadi atau memang butuh transportasi. Dengan begitu, waktu di perjalanan bisa dimanfaatkan untuk hal lainnya. Selain itu, layanan angkutan sewa khusus atau transportasi online juga semakin efisien dengan dukungan uang elektronik. Sehingga Anda tak perlu repot menyiapkan uang tunai.</p>
                </div>
            </div>
        </>
    );
}

export default SRDescription;