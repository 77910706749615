// Navbar
import BackNavbar from "../components/navbar/BackNavbar";

// Content
// import BlankBlog from "../components/blog/BlankBlog";
import FilledBlog from "../components/blog/FilledBlog";
import Headline from "../components/blog/Headline";

// Footer
import Footer2 from "../components/footer/Footer2";

const Blog = () => {
    return (
        <>
            <div className="navbar">
                <BackNavbar />
            </div>
            <div className="content">
                <Headline />
                <FilledBlog />
            </div>
            <div className="footer">
                <Footer2 />
            </div>
        </>
    );
}

export default Blog;