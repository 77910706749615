import "./Navbar.css";
import { useState, useEffect } from "react";
import Logo from "../../images/logo/white-amj.png";
import LogoScroll from "../../images/logo/def-amj.png";
import Bar from "../../images/icon/bar-white.png";
import BarScroll from "../../images/icon/bar-navy.png";
import Times from "../../images/icon/times-white.png";
import TimesScroll from "../../images/icon/times-navy.png";

const Navbar = () => {
    // change nav when scroll
    const [scrolled, setScrolled] = useState(false);

    const handleScroll = () => {
        const isScrolled = window.scrollY > 0;
        setScrolled(isScrolled);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    // change menu icon when clicked

    const [clicked, setClicked] = useState(false);

    const handleMenuClick = () => {
        setClicked(!clicked);
    }

    return (
        <>
            <nav className={`${scrolled ? 'nav-bg ' : 'nav-container'}`}>
                <div className="logo">
                    <img src={`${scrolled ? LogoScroll : Logo}`} alt="AMJ White Logo" />
                </div>
                <div className="menu-icon">
                    <i onClick={handleMenuClick}>
                        <img src={`${scrolled ? (clicked ? TimesScroll : BarScroll) : (clicked ? Times : Bar)}`} alt="AMJ White Logo" />
                    </i>
                </div>
                <div className={`${clicked ? 'links-active' : 'links'}`}>

                    <a href="/">Beranda</a>
                    <a href="#about-us">Tentang Kami</a>
                    <a href="#service">Layanan</a>
                    <a href="/blog">Blog</a>
                    <a href="/artikel">Artikel</a>
                    <a href="#footer">Kontak</a>

                </div>
            </nav>
        </>

    );
}

export default Navbar;