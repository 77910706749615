import "./ServiceDetail.css";

const ADescription = () => {
    return (
        <>
            <div className="detail-desc-container">
                <div className="content">
                    <h1>Periklanan Videotron</h1>
                    <p>Videotron merupakan bentuk dari Iklan digital dengan visual gambar bergerak (DIGITAL VISUAL ADVERTISING). Kelebihan dari videotron yakni memiliki kemampuan dalam menampilan gambar bergerak dibanding dengan bilboard atau baliho, videotron lebih informatif dan lebih menarik.</p>
                    <p>Videotron juga tidak seperti tampilan iklan biasa billboard atau baliho dan lain-lain yang cenderung kaku atau statis dan mudah pudar warnanya atau rusak karena cuaca.</p>
                </div>
            </div>
        </>
    );
}

export default ADescription;