import "./Home.css";

const AboutUs = () => {
    return (
        <>
            <div className="boutus-container" id="about-us">
                <div className="description">
                    <h1>PT. Alfath Mulia Jaya</h1>
                    <p>PT. Alfath Mulia Jaya adalah sebuah perusahaan berbentuk perseroan yang mengkhususkan produknya pada layanan jasa transportasi Angkutan Sewa Khusus yang telah bekerjasama dengan berbagai perusahaan penyedia layanan transportasi berbasis teknologi aplikasi seperti Grab, Gojek, Tiket.com serta Traveloka. </p>
                    <p>Kami juga bekerja sama dengan kementrian BUMN, salah satunya PT. Angkasa Pura I Juanda dan Pemerintah Kota Kabupaten dalam bidang advertising atau periklanan.</p>
                </div>
            </div>
        </>
    );
}

export default AboutUs;