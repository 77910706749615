import React from 'react';
import { useParams } from 'react-router-dom';
import { blogsData } from './data';
import BlogBackNavbar from './../navbar/BlogBackNavbar';
import Footer2 from './../footer/Footer2';

const DetailBlog = () => {
    const { id, title } = useParams();
    const blog = blogsData.find(blog => blog.id === parseInt(id), blog => blog.title === title);

    if (!blog) {
        return <div>Artikel tidak ditemukan</div>;
    }

    return (
        <>
            <div className="navbar">
                <BlogBackNavbar />
            </div>
            <div className='dblog-container'>
                <div className="content">
                    <h2 className="title">{blog.title}</h2>
                    <div className="line"></div>
                    <div className="info-blog">
                        <p>{blog.sumber}</p>
                        <p>{blog.tahun}</p>
                    </div>
                    <img src={blog.image} alt="" className="main-image" />
                    {blog.body.map((paragraph, index) => (
                        <p key={index} className="body">
                            {paragraph}
                            <br />
                            <br />
                        </p>
                    ))}
                    <div className="reference">
                        <b>Referensi:</b>
                        <br />
                        {
                            blog.references.map((reference, i) => (
                                <a href={reference.url}><u>{reference.text} </u><br /></a>
                            ))
                        }
                        <p>-</p>
                    </div>
                    <h2 className="dc-text">Dokumentasi Lainnya:</h2>
                    <div className="albums">
                        {
                            blog.albums.map((album, i) => (
                                <img src={album.photo} alt="" key={album.id} />
                            ))
                        }
                    </div>
                </div>
            </div>
            <div className="footer">
                <Footer2 />
            </div>
        </>
    );
}

export default DetailBlog;