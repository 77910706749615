import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import Blog from "./pages/Blog";
import Artikel from "./pages/Artikel";
import SRDetail from "./pages/SRDetail";
import ADetail from "./pages/ADetail";
// import Test from "./test/display";
// import TestDetail from "./test/testdetail";
import DetailBlog from "./components/blog/DetailBlog";

// Admin
// import Login from "./pages/admin/Login";
// import Register from "./pages/admin/Register";


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />

        {/* Blog Area */}
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:id/:title" element={<DetailBlog />} />

        {/* Testing Area */}
        {/* <Route path="/test" element={<Test />} />
        <Route path="/test/:id/:title" element={<TestDetail />} /> */}

        <Route path="/artikel" element={<Artikel />} />
        <Route path="/special_rent" element={<SRDetail />} />
        <Route path="/advertise" element={<ADetail />} />

        {/* Admin */}
        {/* <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} /> */}

      </Routes>
    </Router>
  );
}

export default App;
