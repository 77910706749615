import "./Home.css";
import { useState } from "react";
import GrabLounge from "../../images/news/grablounge.png";
import Sembako from "../../images/news/sembako.png";
import Terminal from "../../images/news/terminal2.png";

const Achievement = () => {
    const [cards] = useState([
        {
            id: 1,
            image: Sembako,
            title: "Distribusikan Bantuan Sosial Saat COVID-19",
            desc: "PT Alfath Mulia Jaya  ikut berpartisipasi dalam pendistribusian ribuan sembako memanfaatkan tenaga driver ojek online (ojol).",
            link: "https://daerah.sindonews.com/read/43849/704/pt-alfath-mulia-jaya-gandeng-ojol-distribusikan-bantuan-pemerintah-1590239119"
        },
        {
            id: 2,
            image: GrabLounge,
            title: "Kerjasama Membangun Grab Lounge",
            desc: "Grab bekerja sama dengan PT Alfath Mulia Jaya untuk membangun Grab Lounge di Terminal Bungurasih sebagai tempat untuk titik penjemputan penumpang. ",
            link: "https://inisurabaya.com/2019/08/gandeng-pt-alfath-mulia-jaya-grab-hadirkan-lounge-di-terminal-bungurasih-ini-harapan-bupati-saiful-ilah/"
        },
        {
            id: 3,
            image: Terminal,
            title: "Angkutan Online Roda 4 di Terminal Purabaya Diresmikan",
            desc: "Ditjen Hub Darat pengelola Terminal Tipe A Purabaya Bungurasih bekerjasama dengan PT Alfath Mulia Jaya (AMJ) melakukan soft launching angkutan online di Terminal Tipe A Purabaya Bungurasih.",
            link: "https://beritajatim.com/ekbis/angkutan-online-roda-4-di-terminal-purabaya-diresmikan/"
        },
    ]);
    return (
        <>
            <section>
                <div className="achieve-container">
                    <h1>Pencapaian Kami</h1>
                    <div className="cards">
                        {
                            cards.map((card, i) => (
                                <div className="card" key={card.id}>
                                    <img src={card.image} alt="" />
                                    <div className="detail">
                                        <div className="news-data">
                                            <h2>{card.title}</h2>
                                            <p>{card.desc}</p>
                                        </div>
                                        <a href={card.link}><button className="btn-outline-blue">Lihat Berita &gt;</button>                                        </a>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
        </>
    );
}

export default Achievement;