import "./Home.css";

const VisionMission = () => {
    return (
        <div className="vismis-container">
            <div className="vision">
                <h2>Misi <br /> Perusahaan</h2>
                <p>Menyediakan jasa transportasi sesuai dengan permintaan</p>
                <p>Menyediakan tenaga driver dan mekanik yang profesional dalam pelayanan jasa</p>
                <p>Membuat waktu klien semakin efisien</p>
                <p>Ikut serta dalam menciptkan lowongan pekerjaan bagi masyarakat</p>
            </div>
            <div className="mission">
                <h2>Visi <br /> Perusahaan</h2>
                <p>Menjadi perusahaan yang dipercaya sebagai solusi permasalahan otomotif dan transportasi bagi masyarakat</p>
            </div>
        </div>
    );
}

export default VisionMission;