import "./ServiceDetail.css";
import { useState } from "react";
import Grab from "../../images/place/square/grab.png";
import Puskopal from "../../images/place/square/puskopal.png";

const SRLocation = () => {
    const [cards] = useState([
        {
            id: 1,
            image: Grab,
            location: "Grab Lounge Bungurasih",
            detail: "Jl. Letjend Sutoyo No.127, Bungur, Medaeng, Kec. Waru, Kabupaten Sidoarjo, Jawa Timur",
            map: "https://goo.gl/maps/tQTnwFByXha2ybc69",
        },
        {
            id: 2,
            image: Puskopal,
            location: "Transportasi Online Bandara Juanda",
            detail: "Jl. Ir. H. Juanda, Betro, Kec. Sedati, Kabupaten Sidoarjo, Jawa Timur",
            map: "https://goo.gl/maps/TJ43faTTrJbJp6vG6"
        },
    ]);
    return (
        <>
            <section>
                <div className="sr-location-container">
                    <h1 id="title">Lokasi</h1>
                    <div className="cards">
                        {
                            cards.map((card, i) => (
                                <div className="card">
                                    <img src={card.image} alt="" />
                                    <div className="detail">
                                        <div className="loc-data">
                                            <h1>
                                                {card.location}
                                            </h1>
                                            <p>
                                                {card.detail}
                                            </p>
                                        </div>
                                        <a href={card.map}><button className="btn-fill-blue">Lihat di Google Maps</button></a>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
        </>
    );
}

export default SRLocation;