import "./Artikel.css";

const Headline = () => {
    return (
        <>
            <div className="artcl-hl-container">
                <div className="content">
                    <h1>Kata Media Tentang Kami</h1>
                    <p>Artikel dari berbagai macam media informasi tentang PT. Alfath Mulia Jaya</p>
                </div>
            </div>
        </>
    );
}

export default Headline;