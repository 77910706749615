import "./Navbar.css";
import Logo from "../../images/logo/def-amj.png";

const BlogBackNavbar = () => {
    return (
        <nav className="back-nav-container">
            <div className="logo">
                <img src={Logo} alt="AMJ Logo" />
            </div>
            <div className="link">
                <a href="/blog">&lt; Kembali ke Halaman Blog</a>
            </div>
        </nav>
    );
}

export default BlogBackNavbar;