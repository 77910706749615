import "./Navbar.css";
import Logo from "../../images/logo/def-amj.png";

const BackNavbar = () => {
    return (
        <nav className="back-nav-container">
            <div className="logo">
                <img src={Logo} alt="AMJ Logo" />
            </div>
            <div className="link">
                <a href="/">&lt; Kembali ke Beranda</a>
            </div>
        </nav>
    );
}

export default BackNavbar;