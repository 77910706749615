import Terminal from "../../images/news/terminal2.png";

export const articlesData = [
    {
        id: 1,
        image: "https://inisurabaya.com/wp-content/uploads/2019/08/Launching-Grab-Lounge-Bungurasih-9.jpg",
        title: "Kerjasama Membangun Grab Lounge",
        sumber: "IniSurabaya.com",
        tahun: "2019",
        link: "https://inisurabaya.com/2019/08/gandeng-pt-alfath-mulia-jaya-grab-hadirkan-lounge-di-terminal-bungurasih-ini-harapan-bupati-saiful-ilah/"
    },
    {
        id: 2,
        image: "https://pict.sindonews.net/dyn/732/pena/news/2020/05/23/704/43849/pt-alfath-mulia-jaya-gandeng-ojol-distribusikan-bantuan-pemerintah-vii.jpg",
        title: "Distribusikan Bantuan Sosial Saat COVID-19",
        sumber: "daerah.sindonews.com",
        tahun: "2020",
        link: "https://daerah.sindonews.com/read/43849/704/pt-alfath-mulia-jaya-gandeng-ojol-distribusikan-bantuan-pemerintah-1590239119"
    },
    {
        id: 3,
        image: Terminal,
        title: "Angkutan Online Roda 4 di Terminal Purabaya Diresmikan",
        sumber: "beritajatim.com",
        tahun: "2023",
        link: "https://beritajatim.com/ekbis/angkutan-online-roda-4-di-terminal-purabaya-diresmikan/"
    },
    {
        id: 4,
        image: "https://sidoarjosatu.com/wp-content/uploads/2024/06/IMG_20240628_142413-scaled-e1719683859265.jpg",
        title: "Transformasi Digital: Pengelola Terminal Purabaya (Bungurasih) Bakal Realisasikan E-Tiket dan Barcode",
        sumber: "sidoarjosatu.com",
        tahun: "2024",
        link: "https://sidoarjosatu.com/transformasi-digital-pengelola-terminal-purabaya-bungurasih-bakal-realisasikan-e-tiket-dan-barcode/"
    },
];
