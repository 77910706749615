import './Home.css';

const Headline = () => {
    return (
        <div className="hl-container">
            <div className="content">
                <h1>Penyelenggara Angkutan <br />Sewa Khusus dan Periklanan</h1>
                <p>PT. Alfath Mulia Jaya siap menjadi pilihan terbaik anda dalam penyedia  <br />angkutan khusus serta periklanan usaha dan produk anda.</p>
                <div className="button"><a href="#footer"><button className="gap btn-fill-white">Kontak Kami</button></a>
                    <a href="#service"><button className="btn-outline-white">Layanan Kami</button></a></div>

            </div>
        </div>
    );
}

export default Headline;