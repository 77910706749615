import "./Artikel.css";
import { useState } from "react";
import { articlesData } from "./data";
// import Terminal from "../../images/news/terminal2.png";

const Articles = () => {
    const [artcls] = useState(articlesData);

    const [currentPage, setCurrentPage] = useState(1);
    const articlesPerPage = 9;

    const indexOfLastArticle = currentPage * articlesPerPage;
    const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
    const currentArticles = artcls.slice().reverse().slice(indexOfFirstArticle, indexOfLastArticle);

    const totalPages = Math.ceil(artcls.length / articlesPerPage);

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handlePrevPage = () => {
        setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) => (prevPage < totalPages ? prevPage + 1 : prevPage));
    };

    return (
        <>
            <section className="sec-artcls">
                <div className="artcls-container">
                    <div className="cards">
                        {
                            currentArticles.map((artcl, i) => (
                                <a href={artcl.link}>
                                    <div key={i} className="card" >
                                        <img src={artcl.image} alt="" />
                                        <h3 id="title">{artcl.title}</h3>
                                        <div className="artcl-info">
                                            <p>{artcl.sumber}</p>
                                            <p id="divider">/</p>
                                            <p>{artcl.tahun}</p>
                                        </div>
                                    </div>
                                </a>
                            ))
                        }
                    </div>
                    <div className="pagination">
                        <button onClick={handlePrevPage} disabled={currentPage === 1}>
                            Prev
                        </button>
                        {Array.from({ length: totalPages }).map((_, index) => (
                            <button
                                key={index}
                                onClick={() => paginate(index + 1)}
                                className={currentPage === index + 1 ? "selected" : "not-selected"}
                            >
                                {index + 1}
                            </button>
                        ))}
                        <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                            Next
                        </button>
                    </div>
                </div>
            </section>

        </>
    );
}

export default Articles;