import "./ServiceDetail.css";
import { useState } from "react";
import clock from "../../images/icon/clock.png";
import plane from "../../images/icon/plane.png";
import ear from "../../images/icon/ear-to-ear.png";
import rated from "../../images/icon/rated.png";

const ABenefit = () => {
    const [cards] = useState([
        {
            id: 1,
            image: clock,
            title: "Efektif dan Atraktif",
            description: "Iklan yang di tayangkan lebih tertarget dan harapan dari pengguna jasa periklanan videotron menaikkan Brand Image dan Brand Awareness.",
        },
        {
            id: 2,
            image: rated,
            title: "Banyak Dimanfaatkan",
            description: "Pemerintah daerah, kantor instansi, kantor swasta seperti bank, pemerintah daerah juga menggunakan videotron sebagai alat penyampaian program Layanan Masyarakat.",
        },
        {
            id: 3,
            image: ear,
            title: "Media Komunikasi Satu Arah",
            description: "Masyarakat dapat mengetahui program pemerintah dan kantor-kantor swasta seperti bank atau mall dengan mengunakan videotron sebagai alat menampilkan produk atau jasa.",
        },
        {
            id: 4,
            image: plane,
            title: "Promosi Pariwisata",
            description: "Di kota-kota daerah tujuan wisata, saat ini videotron mulai banyak terpasang di bandara-bandara internasional.",
        },

    ]);
    return (
        <section>
            <div className="adds-benefit-container">
                <h1>Keuntungan Promosi Menggunakan Videotron</h1>
                <div className="cards">
                    {
                        cards.map((card, i) => (
                            <div className="card">
                                <img src={card.image} alt="" />
                                <h2>{card.title}</h2>
                                <p>{card.description}</p>
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    );
}

export default ABenefit;