// Navbar
import BackNavbar from "../components/navbar/BackNavbar";

// Content
import SRDescription from "../components/service_detail/SRDescription";
import SRLocation from "../components/service_detail/SRLocation";

// Footer
import Footer from "../components/footer/Footer";

const SRDetail = () => {
    return (
        <>
            <div className="navbar">
                <BackNavbar />
            </div>
            <div className="content">
                <SRDescription />
                <SRLocation />
            </div>
            <div className="footer">
                <Footer />
            </div>
        </>
    );
}

export default SRDetail;