import AP from "../../images/logo/Angkasa Pura.png";
import Grab from "../../images/logo/Grab.png";
import Gojek from "../../images/logo/Gojek.png";
import TC from "../../images/logo/Tiketcom.png";
import Traveloka from "../../images/logo/Traveloka.png";
import "./Home.css"

const Partner = () => {
    return (
        <>
            <div className="partner-container">
                <p>Partner Kami</p>
                <div className="logo">
                    <img src={AP} alt="" />
                    <img src={Grab} alt="" />
                    <img src={Gojek} alt="" />
                    <img src={TC} alt="" />
                    <img src={Traveloka} alt="" />
                </div>
            </div>
        </>
    );
}

export default Partner;