import Icon from "../../images/icon/call-white.png";
import "./Home.css";

const Call = () => {
    return (
        <>
            <div className="call-container" id="help-call">
                <div className="content">
                    <div className="title">
                        <img src={Icon} alt="" />
                        <h1>24 JAM</h1>
                    </div>
                    <p>Kapanpun dan dimanapun
                        <br />kami siap untuk memenuhi kebutuhan
                        <br />anda!
                    </p>
                    <a href="#footer"><button className="btn-outline-white">Hubungi Kami</button></a>

                </div>
            </div>
        </>
    );
}

export default Call;